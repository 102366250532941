<template>
  <div
    class="icon-wrapper d-flex justify-content-center align-items-center"
    :class="!noWrapper && 'wrapper-size'"
    @click="!disabled && $emit('clicked')"
  >
    <img
      :width="width"
      :height="height"
      :class="[disabled ?? 'disabled']"
      :src="imgPath"
      :style="{ width: width + 'px', height: height + 'px' }"
      :alt="altText"
    />
  </div>
</template>

<script>
export default {
  emits: ["clicked"],
  props: {
    width: { type: [String, Number], default: 48 },
    height: { type: [String, Number], default: 48 },
    disabled: { type: Boolean, default: false },
    img: { type: String, default: null },
    altText: { type: String, default: "icon" },
    noWrapper: { type: Boolean, default: false },
  },
  computed: {
    imgPath() {
      return require(`@/assets/img/${this.img}`);
    },
  },
};
</script>

<style scoped>
.wrapper-size {
  min-height: 48px;
  min-width: 48px;
}

.icon-wrapper {
  max-width: max-content;
  max-height: max-content;
  cursor: pointer;
  padding: 0px;
}

.wide {
  width: 12rem;
}

.medium {
  width: 8rem;
}
</style>
